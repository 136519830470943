

































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import echarts from "echarts";
import {
  GetOverview,
  GetRenwuZhuangtaiLeft,
  GetRenwuZhuangtaiRight,
  GetFenpeiNum,
  Statistics,
  ExportExcel,
  GetDataByAccount,
  GetDataByTask,
} from "@/request/mark";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import TimeRange from "@/components/time-range.vue";
@Component({
  components: {
    MainHeader,
    Empty,
    TimeRange,
  },
})
export default class Name extends Vue {
  private loading3: any = false;
  private loading2: any = false;
  private filters: any = {
    开始时间: "",
    结束时间: "",
    way: "按提交计数",
  };
  private cTab: any = "单个实体数";
  private current: any = {};
  private zhuangtaiPie: any = {};
  private shuliangPie: any = {};
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private statusData: any = [];
  private shuliangData: any = {
    单个实体数: {},
    有关系的实体数: {},
    关系数: {},
    详情数据: [],
  };
  private overviewData: any = {};
  private zhuangtaiLeftData: any = [];
  private tableData: any = [
    {
      任务名: "万病回春 卷之一",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
    {
      任务名: "万病回春 卷之二",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
    {
      任务名: "万病回春 卷之三",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
    {
      任务名: "万病回春 卷之四",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
    {
      任务名: "万病回春 卷之五",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
    {
      任务名: "万病回春 卷之六",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
    {
      任务名: "万病回春 卷之七",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
    {
      任务名: "万病回春 卷之八",
      账户名: "admin",
      姓名: "admin",
      手机号: "18820198908",
      单个实体数: "237",
      有关系的实体数: "34",
      关系数: "56",
    },
  ];
  private accountData: any = {};
  private taskData: any = {};
  private changeTab(val: any) {
    this.cTab = val;
    this.drawShuliangPie();
  }
  /**
   * @description 计算各机构服务量分布的背景
   */
  private calBackground(index: any, item: any, first: any) {
    const max = first.value;
    if (!this.$refs.zongliang as any) {
      return {};
    }
    // 总共40个颜色，除40取余
    const style: any = {};
    style.backgroundColor = this.colors[index % 40];
    if (!style.backgroundColor) {
      style.backgroundColor = "#000000";
    }
    const w = (item.value / max) * (this.$refs.zongliang as any).offsetWidth;
    if (w > 0 && w < 40) {
      style.width = 40 + "px";
    } else {
      if (w > 100) {
        style.width = w - 60 + "px";
      } else {
        style.width = w + "px";
      }
    }
    // style.width =
    //   (item.value / max) * (this.$refs.zongliang as any).offsetWidth +
    //   40 +
    //   "px";
    return style;
  }
  private drawStatusPie() {
    this.loading2 = true;
    GetRenwuZhuangtaiLeft(this)
      .then((res: any) => {
        this.loading2 = false;
        this.zhuangtaiLeftData = res.data;
        if (res.data.length === 0) {
          this.current = {};
          this.getRenwuZhuangtaiData();
          return;
        } else {
          this.current = res.data[0];
        }
        setTimeout(() => {
          const Chart: any = echarts.init(
            this.$refs.Echarts as HTMLCanvasElement
          );

          const options = {
            color: this.colors,
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: [
              {
                type: "pie",
                data: res.data,
                radius: ["50%", "70%"],
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: "30",
                    fontWeight: "bold",
                  },
                },
                center: ["60%", "50%"],
              },
            ],
          };
          Chart.setOption(options, true);
          // 点击事件
          Chart.off("click");
          Chart.on("click", (p: any) => {
            this.current = p;
            this.getRenwuZhuangtaiData();
          });
          this.getRenwuZhuangtaiData();
        }, 1000);
      })
      .catch(() => {
        this.loading2 = false;
      });
  }
  private getRenwuZhuangtaiData() {
    const params: any = {
      name: this.current.name,
    };
    GetRenwuZhuangtaiRight(this, params).then((res: any) => {
      this.statusData = res.data;
    });
  }
  private getGongzuoliangData() {
    const params: any = {
      target: this.filters.way,
      start_time: this.filters["开始时间"],
      end_time: this.filters["结束时间"],
    };
    this.loading3 = true;
    Statistics(this, params)
      .then((res: any) => {
        this.loading3 = false;
        this.shuliangData = res;
        this.drawShuliangPie();
      })
      .catch(() => {
        this.loading3 = false;
      });
  }
  private drawShuliangPie() {
    const data: any = this.shuliangData[this.cTab];
    this.shuliangPie = {
      color: this.colors,
      tooltip: {
        trigger: "item",
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          type: "pie",
          data: data.data,
          radius: ["50%", "70%"],
          label: {
            show: false,
          },
          center: ["60%", "50%"],
        },
      ],
    };
  }
  private getOverview() {
    GetOverview(this).then((res: any) => {
      this.overviewData = res;
    });
  }
  private timeChange(e: any) {
    this.filters = e;
    this.getGongzuoliangData();
  }
  private exportData(d: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      data_type: "object",
      data_content: d["全部数据"],
      headers: d["headers"],
    };
    ExportExcel(this, params, loading)
      .then((res: any) => {
        loading.close();
        window.open(res.url);
      })
      .catch(() => {
        loading.close();
      });
  }
  // 工作量统计-按任务名展示详情数据
  private getDataByTask() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetDataByTask(this, loading).then((res: any) => {
      loading.close();
      this.taskData = res;
    });
  }
  // 工作量统计-按账户名展示详情数据
  private getDataByAccount() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetDataByAccount(this, loading).then((res: any) => {
      loading.close();
      this.accountData = res;
    });
  }
  private mounted() {
    this.getOverview();
    this.drawStatusPie();
    this.getGongzuoliangData();
    this.getDataByTask();
    this.getDataByAccount();
  }
}
