





























import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  @Prop()
  private data: any;
 
  private get cData() {
    return this.data;
  }
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.cData["结束时间"]) {
          return (
            time.getTime() > new Date(self.cData["结束时间"]).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.cData["开始时间"]) {
          return (
            new Date(self.cData["开始时间"]).getTime() > time.getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private valueChange(e: any) {
    this.$emit("change", this.cData);
  }
}
